import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Box, Grid, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import { defaultStyle } from "components/Modals";
import { updateContractsEndDate } from "api/resources/contracts";
import { GridRowSelectionModel } from "@mui/x-data-grid";

interface EditContractModalProps {
  selectedMeteringPoints: GridRowSelectionModel;
  setSelectedMeteringPoints: React.Dispatch<
    React.SetStateAction<GridRowSelectionModel>
  >;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditContractModal = ({
  setOpenModal,
  selectedMeteringPoints,
  setSelectedMeteringPoints,
}: EditContractModalProps) => {
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const postData = {
      endDate: dayjs(endDate).format("YYYY-MM-DD").toString(),
      meteringPoints: selectedMeteringPoints,
    };

    updateContractsEndDate(postData)
      .then(() => {
        setLoading(false);
        setOpenModal(false);
        setSelectedMeteringPoints([]);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box sx={defaultStyle}>
      <Typography id="modal-modal-title" variant="h5" component="h5">
        Change the end date of the contract
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2} sx={{ mt: "1rem", mb: "2rem" }}>
          <Grid item sm={6}>
            <DatePicker
              label="End date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              slotProps={{ textField: { fullWidth: true, size: "small" } }}
            />
          </Grid>
          <Grid item sm={12} sx={{ mt: "1rem" }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              loadingIndicator="Sending..."
              disabled={loading}
            >
              Save and send to AVP
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EditContractModal;

export const ForecaSymbols = [
  { code: "d000", label: "Clear" },
  { code: "d100", label: "Mostly clear" },
  { code: "d200", label: "Partly cloudy" },
  { code: "d210", label: "Partly cloudy and light rain" },
  { code: "d220", label: "Partly cloudy and showers" },
  { code: "d240", label: "Partly cloudy, thunderstorms with rain" },
  { code: "d211", label: "Partly cloudy and light wet snow" },
  { code: "d221", label: "Partly cloudy and wet snow showers" },
  { code: "d212", label: "Partly cloudy and light snow" },
  { code: "d222", label: "Partly cloudy and snow showers" },
  { code: "d300", label: "Cloudy" },
  { code: "d310", label: "Cloudy and light rain" },
  { code: "d320", label: "Cloudy and showers" },
  { code: "d340", label: "Cloudy, thunderstorms with rain" },
  { code: "d311", label: "Cloudy and light wet snow" },
  { code: "d321", label: "Cloudy and wet snow showers" },
  { code: "d312", label: "Cloudy and light snow" },
  { code: "d322", label: "Cloudy and snow showers" },
  { code: "d400", label: "Overcast" },
  { code: "d410", label: "Overcast and light rain" },
  { code: "d420", label: "Overcast and showers" },
  { code: "d430", label: "Overcast and rain" },
  { code: "d440", label: "Overcast, thunderstorms with rain" },
  { code: "d411", label: "Overcast and light wet snow" },
  { code: "d421", label: "Overcast and wet snow showers" },
  { code: "d431", label: "Overcast and wet snow" },
  { code: "d412", label: "Overcast and light snow" },
  { code: "d422", label: "Overcast and snow showers" },
  { code: "d432", label: "Overcast and snow" },
  { code: "d500", label: "Thin upper cloud" },
  { code: "d600", label: "Fog" },
];

import React from "react";
import {
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProductionParkComponentType } from "../../../../../api/models/enums/ProductionParkComponentType";
import { SelectChangeEvent } from "@mui/material/Select";

interface ComponentFormProps {
  data: {
    id: number | undefined;
    type: string;
    maxPower: number;
    count: number;
  };
  handleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
    index: number,
  ) => void;
  handleRemove: (index: number) => void;
  index: number;
}

const ComponentForm: React.FC<ComponentFormProps> = ({
  data,
  handleChange,
  handleRemove,
  index,
}) => {
  const productionParkTypes = [
    { value: ProductionParkComponentType.SOLAR_PANEL, label: "Solar panel" },
    {
      value: ProductionParkComponentType.WIND_GENERATOR,
      label: "Wind generator",
    },
    { value: ProductionParkComponentType.INVERTER, label: "Inverter" },
    { value: ProductionParkComponentType.NETWORK, label: "Network" },
  ];

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12}>
        <Divider style={{ paddingBottom: 25 }} />
      </Grid>
      <Grid item xs={3}>
        <Select
          fullWidth
          label="Type"
          name="type"
          size="small"
          value={data.type}
          onChange={(e) => handleChange(e, index)}
        >
          {productionParkTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Max power"
          name="maxPower"
          size="small"
          value={data.maxPower}
          onChange={(e) => handleChange(e, index)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          size="small"
          label="Count"
          name="count"
          value={data.count}
          onChange={(e) => handleChange(e, index)}
        />
      </Grid>
      <Grid item xs={3}>
        <IconButton onClick={() => handleRemove(index)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ComponentForm;

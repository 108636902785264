import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { ProtectedPaths } from "routes";
import dayjs from "dayjs";
import { Forecast } from "../../../../api/models/Forecast";

interface HeaderProps {
  forecast: Forecast;
}

const Header: React.FC<HeaderProps> = ({ forecast }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          sx={{ mr: "1rem" }}
          onClick={() => navigate(`/${ProtectedPaths.ManageSamples}`)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component="h5">
          Edit forecast
          <span style={{ color: grey[500] }}>
            {` - ${dayjs(forecast.prognoseDate).format(
              "DD.MM.YYYY",
            )} - ${dayjs(forecast.prognoseDate).format("dddd")}`}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;

import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AuthProvider from "provider/authProvider";
import { Provider } from "react-redux";
import { store } from "store";
import updateLocale from "dayjs/plugin/updateLocale";
import { indigo } from "@mui/material/colors";
import "dayjs/locale/en-gb";
import isTomorrow from "dayjs/plugin/isTomorrow"; // ES 2015
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
dayjs.extend(isTomorrow);
dayjs.extend(updateLocale);
dayjs.updateLocale("en-gb", {
  weekStart: 1,
});

import Routes from "./routes";

const App = () => {
  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: indigo[700],
      },
    },
  });

  return (
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <AuthProvider>
            <Provider store={store}>
              <Routes />
            </Provider>
          </AuthProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;

import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Table from "components/Table";
import { fetchAllMeteringPoints } from "store/appSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectMeteringPoints } from "store/selectors";
import { MeteringPointTypes } from "helpers/enums";
import FilterComponent from "../../../../../components/FilterComponent/FilterComponent";
import { MeteringPoint } from "../../../../../api/models/MeteringPoint";

interface RowType {
  row: {
    original: MeteringPoint;
  };
}

const ClientsTable = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [resetPage, setResetPage] = useState(false);

  const dispatch = useAppDispatch();
  const meteringPoints = useAppSelector(selectMeteringPoints);

  useEffect(() => {
    dispatch(fetchAllMeteringPoints(currentPage, 25, filters));
  }, [currentPage]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        setCurrentPage(0);
        setResetPage(true);
        dispatch(fetchAllMeteringPoints(currentPage, 25, filters));
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [filters, dispatch]);

  const handleFilter = (newFilters: { [key: string]: string }) => {
    setFilters(newFilters);
  };

  const columns = [
    {
      accessorKey: "eic",
      header: "EIC",
    },
    {
      accessorKey: "address",
      header: "Address",
    },
    {
      accessorKey: "city",
      header: "City",
    },
    {
      accessorKey: "connectionState",
      header: "Connection status",
      cell: (row: RowType) => {
        return MeteringPointTypes[
          row.row.original.connectionState ? "CONNECTED" : "DISCONNECTED"
        ];
      },
    },
    {
      accessorKey: "consumptionScale",
      header: "Scale",
      cell: (row: RowType) => {
        return MeteringPointTypes[
          row.row.original.consumptionScale as keyof typeof MeteringPointTypes
        ];
      },
    },
    {
      accessorKey: "meteringType",
      header: "Type",
      cell: (row: RowType) => {
        return MeteringPointTypes[
          row.row.original.meteringType ? "REMOTE_READING" : "ONE"
        ];
      },
    },
    {
      accessorKey: "isBorderPoint",
      header: "Border",
      cell: (row: RowType) => {
        return MeteringPointTypes[
          row.row.original.isBorderPoint ? "true" : "false"
        ];
      },
    },
    {
      accessorKey: "operatorEic",
      header: "Operator EIC",
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Metering points</Typography>
      </Box>
      <FilterComponent
        columns={[
          "eic",
          "address",
          "city",
          "connectionState",
          "consumptionScale",
          "meteringType",
          "isBorderPoint",
          "operatorEic",
        ]}
        columnPlaceholders={[
          "EIC",
          "Address",
          "City",
          "Connection status",
          "Scale",
          "Type",
          "Border",
          "Operator EIC",
        ]}
        onFilter={handleFilter}
      />
      <Table
        data={meteringPoints?.content}
        isFetching={!meteringPoints}
        columns={columns}
        pageCount={meteringPoints?.totalPages}
        page={setCurrentPage}
        resetPage={resetPage}
        searchLabel="Search for metering point"
      />
    </>
  );
};

export default ClientsTable;

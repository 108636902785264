import MainLayout from "layouts/Main";

import OperatorsTable from "./OperatorsTable";

const Users = () => {
  return (
    <MainLayout
      tabs={[
        { label: "Users", path: "Users" },
        { label: "Operators", path: "Operators" },
        { label: "Litgrid", path: "Litgrid" },
        { label: "Foreca", path: "ForecaSettings" },
      ]}
    >
      <OperatorsTable />
    </MainLayout>
  );
};

export default Users;

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Table from "components/Table";
import { fetchAllContracts } from "store/appSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectContracts } from "store/selectors";
import { ProtectedPaths } from "routes";
import FilterComponent from "../../../../../components/FilterComponent/FilterComponent";

const ContractsTable = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});

  const dispatch = useAppDispatch();
  const contracts = useAppSelector(selectContracts);

  useEffect(() => {
    dispatch(fetchAllContracts(currentPage, 25, filters));
  }, [currentPage, filters]);

  const handleFilter = (newFilters: { [key: string]: string }) => {
    setFilters(newFilters);
  };
  const columns = [
    {
      accessorKey: "meteringPoint",
      header: "Metering point",
      cell: (row: any) => {
        return (
          row.row.original.meteringPoint.address +
          " " +
          row.row.original.meteringPoint.city +
          " (" +
          row.row.original.meteringPoint.eic +
          ")"
        );
      },
    },
    {
      accessorKey: "client",
      header: "Client",
      cell: (row: any) => {
        return (
          <Link to={`/${ProtectedPaths.Clients}/${row.row.original.client.id}`}>
            {row.row.original.client.name +
              " (" +
              row.row.original.client.eic +
              ")"}
          </Link>
        );
      },
    },
    {
      accessorKey: "firstDate",
      header: "Beginning of contract",
    },
    {
      accessorKey: "lastDate",
      header: "End of contract",
    },
    {
      accessorKey: "settings",
      header: "",
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Contracts</Typography>
      </Box>
      <FilterComponent
        onFilter={handleFilter}
        columns={["meteringPoint", "client", "firstDate", "lastDate"]}
        columnPlaceholders={[
          "Metering point",
          "Client",
          "Beginning of contract",
          "End of contract",
        ]}
      />
      <Table
        data={contracts?.content}
        columns={columns}
        isFetching={!contracts}
        pageCount={contracts?.totalPages}
        page={setCurrentPage}
        searchLabel="Search for client"
      />
    </>
  );
};

export default ContractsTable;

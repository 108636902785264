import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import MainLayout from "layouts/Main";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectForecast } from "store/selectors";
import { fetchForecast } from "store/appSlice";
import {
  confirmForecast,
  unConfirmForecast,
  updateForecast,
} from "api/resources/forecasts";
import Filters from "../../Filters";
import {
  ForecastPeriodConsumption,
  ForecastPeriodData,
  RowValue,
  SelectedHour,
} from "../../../../../api/models/Forecast";
import ForecastActions from "../../Components/ForecastActions";
import Header from "../../Components/Header";
import ForecastDetails from "../../Components/ForecastDetails";
import MainContent from "../../Components/ForecastMainContent";
import { ProtectedPaths } from "routes";

interface Day {
  active: boolean;
}

const Reports = () => {
  const [periodsData, setPeriodsData] = useState<ForecastPeriodData[]>([]);
  const [averageLine, setAverageLine] = useState<number[]>([]);
  const [snackbar, setSnackbar] = useState(null);
  const [forecastLine, setForecastLine] = useState<number[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const { sampleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const forecast = useAppSelector(selectForecast);
  const [formValues, setFormValues] = useState({
    customDates: [],
    selectedClients: [],
    selectedMeteringPointsOptions: [],
  });
  const [tableMatrix, setTableMatrix] = useState<any[]>([]);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    dispatch(fetchForecast(sampleId));
  }, [dispatch, sampleId]);

  useEffect(() => {
    const periodsDataInitial: ForecastPeriodData[] = [];

    if (forecast) {
      if (!dayjs(forecast.prognoseDate).isBefore(dayjs())) {
        setIsEditable(true);
      } else {
        setIsEditable(false);
      }

      const newFormValues = formValues;

      newFormValues.customDates = forecast.customDates || [];
      newFormValues.selectedClients = forecast.filtersJson[0].clients || [];
      newFormValues.selectedMeteringPointsOptions =
        forecast.filtersJson[0].meteringPoints || [];

      setFormValues(newFormValues);

      const cells = Object.keys(forecast.cellsJson).map((key) => [
        key,
        forecast.cellsJson[key],
      ]);

      cells.map((time) => {
        const cell = time[1] as ForecastPeriodConsumption;
        const getDate = cell.datetime.split(" ")[0];

        if (periodsDataInitial.filter((r) => r.date === getDate).length > 0) {
          const index = periodsDataInitial.findIndex(
            (row) => row.date === getDate,
          );

          periodsDataInitial[index]?.readings.push({
            datetime: cell.datetime,
            selected: cell.selected,
            totalConsumption: cell.totalConsumption,
            isMissingData:
              cell.meteringPointsWithConsumption < cell.meteringPointsTotal,
            meteringPointsWithConsumption: cell.meteringPointsWithConsumption,
            meteringPointsTotal: cell.meteringPointsTotal,
          });
        } else {
          periodsDataInitial.push({
            date: getDate,
            day: dayjs(getDate).format("dddd"),
            readings: [
              {
                datetime: cell.datetime,
                selected: cell.selected,
                totalConsumption: cell.totalConsumption || 0,
                isMissingData:
                  cell.meteringPointsWithConsumption < cell.meteringPointsTotal,
                meteringPointsWithConsumption:
                  cell.meteringPointsWithConsumption,
                meteringPointsTotal: cell.meteringPointsTotal,
              },
            ],
          });
        }
      });
    }

    setPeriodsData(periodsDataInitial);
  }, [forecast]);

  const handleSubmitFilters = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const forecastData = {
      customDates: formValues.customDates,
      filters: [
        {
          clients: formValues.selectedClients,
          meteringPoints: formValues.selectedMeteringPointsOptions,
        },
      ],
    };

    updateForecast(sampleId, forecastData)
      .then(() => {
        dispatch(fetchForecast(sampleId));
        setSnackbar({
          type: "success",
          message: "Filters saved",
        });
      })
      .catch((error) => {
        console.log({ error });
        setSnackbar({
          type: "error",
          message: "Something went wrong when saving the forecast",
        });
      });
  };

  const handleSaveForecast = () => {
    const rowValues: RowValue[] = [];
    const selectedHours: SelectedHour[] = [];

    setSaving(true);

    tableMatrix.map((row, rowIndex) => {
      row.readings.map((day: Day, dayIndex: number) => {
        const hour = periodsData[dayIndex].readings[rowIndex].datetime;
        selectedHours.push({
          [hour]: day.active || false,
        });
      });

      rowValues.push({
        hour: rowIndex,
        adjustment: Number(row.correction),
        value: row.forecast,
      });
    });

    const forecastData = {
      prognoseValues: rowValues,
      selectedPeriods: selectedHours,
    };

    updateForecast(sampleId, forecastData)
      .then(() => {
        setSaving(false);
        navigate(`/${ProtectedPaths.ManageSamples}`);
      })
      .catch((error) => {
        console.log({ error });
        setSaving(false);
        setSnackbar({
          type: "error",
          message: "Something went wrong when saving the forecast",
        });
      });
  };

  const handleConfirmForecast = () => {
    confirmForecast(Number(sampleId)).then(() => {
      setSnackbar({
        type: "success",
        message: "Forecast confirmed",
      });
      dispatch(fetchForecast(sampleId));
    });
  };

  const handleUnconfirmForecast = () => {
    unConfirmForecast(sampleId).then(() => {
      setSnackbar({
        type: "info",
        message: "Forecast opened for changes",
      });
      dispatch(fetchForecast(sampleId));
    });
  };

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(null);
  };

  if (!forecast) return null;

  return (
    <MainLayout
      tabs={[
        { label: "Manage samples", path: "ManageSamples" },
        { label: "Saved samples", path: "SavedSamples" },
      ]}
      snackbar={{
        open: snackbar,
        handleClose: snackbarClose,
        type: snackbar?.type,
        message: snackbar?.message,
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Header forecast={forecast} />
        <ForecastActions
          isEditable={isEditable}
          isVerified={forecast.isVerified}
          handleConfirmForecast={handleConfirmForecast}
          handleSaveForecast={handleSaveForecast}
          handleUnconfirmForecast={handleUnconfirmForecast}
          saving={saving}
        />
      </Box>
      {isEditable && (
        <Filters
          formValues={formValues}
          setFormValues={setFormValues}
          handleSubmit={handleSubmitFilters}
          submitText="Save filters"
          showButtons={true}
          loading={false}
        />
      )}
      <ForecastDetails forecast={forecast} />
      <MainContent
        periodsData={periodsData}
        setPeriodsData={setPeriodsData}
        setAverageLine={setAverageLine}
        setForecastLine={setForecastLine}
        tableMatrix={tableMatrix}
        setTableMatrix={setTableMatrix}
        isEditable={isEditable}
        forecast={forecast}
        averageLine={averageLine}
        forecastLine={forecastLine}
      />
    </MainLayout>
  );
};

export default Reports;

import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

interface FilterComponentProps {
  columns: string[];
  columnPlaceholders: string[];
  onFilter: (filters: { [key: string]: string }) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  columns,
  columnPlaceholders,
  onFilter,
}) => {
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [dateFilters, setDateFilters] = useState<{
    [key: string]: { gte: string; lte: string };
  }>({});

  useEffect(() => {
    const delayInputTimeout = setTimeout(() => {
      handleSearch();
    }, 500);
    return () => clearTimeout(delayInputTimeout);
  }, [filters]);

  const handleInputChange = (field: string, value: string | null) => {
    if (field.startsWith("gte:") || field.startsWith("lte:")) {
      const [operator, dateField] = field.split(":");
      setDateFilters((prevDateFilters) => ({
        ...prevDateFilters,
        [dateField]: {
          ...prevDateFilters[dateField],
          [operator]: value ? dayjs(value).format("DD-MM-YYYY") : "",
        },
      }));
    } else {
      setFilters((prevFilters) => ({ ...prevFilters, [field]: value || "" }));
    }
  };

  const handleSearch = () => {
    const formattedFilters = { ...filters };

    Object.keys(dateFilters).forEach((dateField) => {
      const { gte, lte } = dateFilters[dateField];
      const formattedGte = gte ? dayjs(gte).format("DD-MM-YYYY") : "";
      const formattedLte = lte ? dayjs(lte).format("DD-MM-YYYY") : "";
      if (formattedGte && formattedLte) {
        formattedFilters[dateField] = `gte:${formattedGte}&lte:${formattedLte}`;
      } else if (formattedGte) {
        formattedFilters[dateField] = `gte:${formattedGte}`;
      } else if (formattedLte) {
        formattedFilters[dateField] = `lte:${formattedLte}`;
      }
    });
    onFilter(formattedFilters);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleDateChange = (
    newValue: any,
    column: string,
    type: "gte" | "lte",
  ) => {
    const formattedDate = newValue ? dayjs(newValue).format("YYYY-MM-DD") : "";
    setDateFilters((prevDateFilters) => ({
      ...prevDateFilters,
      [column]: {
        ...prevDateFilters[column],
        [type]: formattedDate,
      },
    }));
  };

  return (
    <Box
      sx={{
        background: grey[100],
        borderRadius: "4px",
        padding: "1.4rem",
        paddingTop: "0.6rem",
        mt: "1rem",
        maxWidth: "100%",
      }}
    >
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap"
      >
        {columns.map((column, index) => (
          <Box key={column} sx={{ mt: "0.4rem", mr: "1rem" }}>
            <Typography variant="subtitle1">
              {columnPlaceholders[index]}
            </Typography>
            {column === "firstDate" || column === "lastDate" ? (
              <Box
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"wrap"}
                onKeyDown={handleKeyPress}
              >
                <DatePicker
                  label={"From"}
                  sx={{ background: "white", mr: "0.4rem" }}
                  slotProps={{
                    textField: { size: "small" },
                    clearButton: { size: "small" },
                  }}
                  value={
                    dateFilters[column]?.gte
                      ? dayjs(dateFilters[column]?.gte, "YYYY-MM-DD")
                      : null
                  }
                  onChange={(newValue) => {
                    handleDateChange(newValue, column, "gte");
                    handleSearch();
                  }}
                />
                <DatePicker
                  label={"To"}
                  sx={{ background: "white" }}
                  slotProps={{ textField: { size: "small" } }}
                  value={
                    dateFilters[column]?.lte
                      ? dayjs(dateFilters[column]?.lte, "YYYY-MM-DD")
                      : null
                  }
                  onChange={(newValue) => {
                    handleDateChange(newValue, column, "lte");
                    handleSearch();
                  }}
                />
              </Box>
            ) : (
              <TextField
                size="small"
                variant="outlined"
                sx={{ background: "white" }}
                value={filters[column] || ""}
                onChange={(e) => {
                  handleInputChange(column, e.target.value);
                }}
                onKeyDown={handleKeyPress}
              />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FilterComponent;

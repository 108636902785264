import { useState } from "react";
import MainLayout from "layouts/Main";
import { Box, Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useDropzone } from "react-dropzone";
import { sendExcelToLitgrid } from "../../../../api/resources/litgrid";

const LitgridIntegration = () => {
  const [uploadedFile, setUploadedFile] = useState<File>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(null);

  const onDrop = (acceptedFiles: any) => {
    setUploadedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    maxFiles: 1,
  });

  const handleProcessFile = () => {
    if (uploadedFile) {
      sendExcelToLitgrid(uploadedFile).then((response) => {
        const xmlContent = response.data;
        const blob = new Blob([xmlContent], { type: "application/xml" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = url;
        a.download = `${uploadedFile.name}.xml`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setUploadedFile(null);
      });
    }
  };

  return (
    <MainLayout
      tabs={[
        { label: "Users", path: "Users" },
        { label: "Operators", path: "Operators" },
        { label: "Litgrid", path: "Litgrid" },
        { label: "Foreca", path: "ForecaSettings" },
      ]}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Litgrid integration</Typography>
      </Box>
      <Typography component="p" sx={{ mt: "0.5rem", color: grey[700] }}>
        Here you can upload the CSV schedule document that will be sent to
        Litgrid via API.
      </Typography>
      <Box sx={{ mt: "2rem" }}>
        <Box
          {...getRootProps()}
          sx={{
            border: "2px dashed grey",
            padding: "20px",
            cursor: "pointer",
            backgroundColor: isDragActive ? grey[200] : grey[100],
            maxWidth: "700px",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography>Drop the file here</Typography>
          ) : (
            <Typography component="p">
              Drag and drop the file here, or click to select from computer
            </Typography>
          )}
        </Box>

        {uploadedFile && (
          <Box mt={4}>
            <Typography component="p">Uploaded file:</Typography>
            <Typography
              component="p"
              style={{
                marginTop: "0.4rem",
                backgroundColor: grey[100],
                maxWidth: "max-content",
                padding: "6px 12px",
              }}
            >
              {uploadedFile.name}
            </Typography>
          </Box>
        )}

        {uploadedFile && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleProcessFile}
            sx={{ mt: 2 }}
            disabled={!uploadedFile}
          >
            Download XML
          </Button>
        )}
      </Box>
    </MainLayout>
  );
};

export default LitgridIntegration;

import MainLayout from "layouts/Main";
import ForecaTable from "./ForecaTable";

const ForecaSettings = () => {
  return (
    <MainLayout
      tabs={[
        { label: "Users", path: "Users" },
        { label: "Operators", path: "Operators" },
        { label: "Litgrid", path: "Litgrid" },
        { label: "Foreca", path: "ForecaSettings" },
      ]}
    >
      <ForecaTable />
    </MainLayout>
  );
};
export default ForecaSettings;

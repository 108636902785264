import axiosClient from "../axiosInstance";
import {
  ProductionParkData,
  ProductionParkRequest,
} from "../models/ProductionPark";
import { parseAxiosError } from "./helpers/util";

export const getAllProductionParks = async (
  page: number,
  size: number,
): Promise<ProductionParkData> => {
  const client = axiosClient();

  const queryParams = new URLSearchParams({
    page: page.toString(),
    size: size.toString(),
  }).toString();

  const response = await client.get(`/production-parks?${queryParams}`);

  return response.data;
};

export const getProductionParkById = async (
  productionParkId: number,
): Promise<ProductionParkData> => {
  const client = axiosClient();

  const response = await client.get(`/production-parks/${productionParkId}`);

  return response.data;
};

export const createProductionPark = async (
  data: ProductionParkRequest,
): Promise<ProductionParkData> => {
  const client = axiosClient();

  try {
    const response = await client.post(`/production-parks`, data);
    return response.data;
  } catch (error) {
    const message = parseAxiosError(error);
    throw Error(message);
  }
};

export const updateProductionPark = async (
  productionParkId: number,
  data: Partial<ProductionParkRequest>,
): Promise<ProductionParkData> => {
  const client = axiosClient();

  try {
    const response = await client.put(
      `/production-parks/${productionParkId}`,
      data,
    );
    return response.data;
  } catch (error) {
    const message = parseAxiosError(error);
    throw Error(message);
  }
};

export const deleteProductionPark = async (productionParkId: number) => {
  const client = axiosClient();

  const response = await client.delete(`/production-parks/${productionParkId}`);

  return response.data;
};

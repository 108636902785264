import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { ProtectedPaths } from "routes";
import MainLayout from "layouts/Main";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import { createForecast, getForecastTemplate } from "api/resources/forecasts";
import { fetchAllForecasts } from "store/appSlice";
import { useAppDispatch } from "hooks";
import Filters from "../../../Filters";

const CreateForecast = () => {
  const navigate = useNavigate();
  const { forecastId, forecastDate } = useParams();
  const [loading, setLoading] = useState(false);
  const [forecast, setForecast] = useState(null);
  const [formValues, setFormValues] = useState({
    customDates: [],
    selectedClients: [],
    selectedMeteringPointsOptions: [],
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (forecastId) {
      getForecastTemplate(forecastId)
        .then((response) => {
          const template = response.data;
          const initialClients = template.filters[0].clients.map((client) => ({
            title:
              client.companyName || `${client.firstName} ${client.lastName}`,
            value: client.id,
          }));
          const initialMeteringPoints = template.filters[0].meteringPoints.map(
            (mp) => ({
              title: mp.eic,
              value: mp.id,
            }),
          );
          const initialCustomDates = template.customDates || [];
          setForecast(template);
          setFormValues({
            customDates: initialCustomDates,
            selectedClients: initialClients,
            selectedMeteringPointsOptions: initialMeteringPoints,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [forecastId]);

  const handleCreatePrognosis = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const forecastData = {
      date: forecastDate,
      templateId: forecastId,
      filters: [
        {
          clients: formValues.selectedClients.map((client) => client.value),
          meteringPoints: formValues.selectedMeteringPointsOptions.map(
            (mp) => mp.value,
          ),
        },
      ],
      customDates: formValues.customDates.map((date) =>
        dayjs(date).format("YYYY-MM-DD"),
      ),
    };

    createForecast(forecastData)
      .then((response) => {
        dispatch(fetchAllForecasts());
        setLoading(false);
        navigate(`/${ProtectedPaths.EditSample}/${response.data.id}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MainLayout
      tabs={[
        { label: "Manage samples", path: "ManageSamples" },
        { label: "Saved samples", path: "SavedSamples" },
      ]}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          sx={{ mr: "1rem" }}
          onClick={() => navigate(`/${ProtectedPaths.ManageSamples}`)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component="h5">
          Create forecast
          <span style={{ color: grey[500] }}>
            {` - ${dayjs(forecastDate).format("DD.MM.YYYY")} - ${dayjs(
              forecastDate,
            ).format("dddd")}`}
          </span>
        </Typography>
      </Box>
      {forecast && (
        <Filters
          formValues={formValues}
          setFormValues={setFormValues}
          handleSubmit={handleCreatePrognosis}
          submitText="Create"
          loading={loading}
          handleCancel={() => navigate(`/${ProtectedPaths.ManageSamples}`)}
          cancelText="Cancel"
          showButtons={true}
        />
      )}
    </MainLayout>
  );
};

export default CreateForecast;

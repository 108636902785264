import React, { useEffect, useState } from "react";
import MainLayout from "../../../../../layouts/Main";
import { Alert, Box, IconButton, Snackbar, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  fetchClient,
  fetchProductionPark,
} from "../../../../../store/appSlice";
import {
  selectClient,
  selectProductionPark,
} from "../../../../../store/selectors";
import { ProductionPark } from "../../../../../api/models/ProductionPark";
import { ProductionParkType } from "../../../../../api/models/enums/ProductionParkType";
import {
  deleteProductionPark,
  updateProductionPark,
} from "../../../../../api/resources/productionPark";
import { ProtectedPaths } from "../../../../../routes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProductionParkForm from "../Components/ProductionParkForm";
import { useProductionParkForm } from "../Util/util";
import { ApiError } from "../AddProductionPark";

const EditProductionPark: React.FC = () => {
  const { productionParkId } = useParams<{ productionParkId: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const productionPark = useAppSelector(selectProductionPark);
  const client = useAppSelector(selectClient);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success",
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const initialFormData: ProductionPark = {
    id: undefined,
    name: "",
    type: ProductionParkType.SOLAR,
    code: "",
    location: "",
    priceArea: "",
    clientId: 0,
    productionParkComponents: [],
    meteringPoints: [],
  };

  const {
    formData,
    setFormData,
    selectedCountry,
    setSelectedCountry,
    selectedClients,
    setSelectedClients,
    handleChange,
    handleRemoveComponent,
    handleAddComponent,
    handleCountrySelect,
  } = useProductionParkForm(initialFormData);

  useEffect(() => {
    if (productionParkId) {
      dispatch(fetchProductionPark(Number(productionParkId)));
    }
  }, [productionParkId, dispatch]);

  useEffect(() => {
    if (productionPark && productionPark.clientId.toString() != "") {
      dispatch(fetchClient(productionPark.clientId.toString()));
      setFormData(productionPark);
      setSelectedCountry({
        code: productionPark.priceArea,
        label: productionPark.priceArea,
        phone: productionPark.priceArea,
      });
    }
  }, [productionPark, dispatch, setFormData, setSelectedCountry]);

  useEffect(() => {
    if (client) {
      const clientName =
        client.type === "BUSINESS"
          ? client.companyName
          : `${client.firstName} ${client.lastName}`;
      setSelectedClients([{ title: clientName, value: client.id }]);
    }
  }, [client, setSelectedClients]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const clientId = selectedClients[0]?.value || 0;
    const meteringPointIds = formData.meteringPoints
      .filter((point) => point !== null)
      .map((point) => point.id);

    const postData = {
      ...formData,
      clientId,
      meteringPoints: meteringPointIds,
    };

    try {
      await updateProductionPark(Number(productionParkId), postData);
      setSnackbarMessage("Production park submitted successfully");
      setSnackbarSeverity("success");
    } catch (error) {
      const apiError = error as ApiError;
      setSnackbarMessage(apiError.message);
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDelete = async () => {
    try {
      await deleteProductionPark(Number(productionParkId));
      setSnackbarMessage("Production park deleted successfully");
      setSnackbarSeverity("success");
      navigate(`/${ProtectedPaths.ProductionParks}`);
    } catch (error) {
      const apiError = error as ApiError;
      setSnackbarMessage(apiError.message);
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
      setDeleteModalOpen(false);
    }
  };

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  return (
    <MainLayout
      tabs={[
        { label: "Clients", path: "Clients" },
        { label: "Metering points", path: "MeteringPoints" },
        { label: "Contracts", path: "ClientContracts" },
        { label: "Production parks", path: "ProductionParks" },
      ]}
    >
      <Box sx={{ display: "flex", alignItems: "center", paddingBottom: 3 }}>
        <IconButton
          sx={{ mr: "1rem" }}
          onClick={() => navigate(`/${ProtectedPaths.ProductionParks}`)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component="h5">
          Edit production park
        </Typography>
      </Box>
      <ProductionParkForm
        formData={formData}
        setFormData={setFormData}
        selectedCountry={selectedCountry}
        handleCountrySelect={handleCountrySelect}
        selectedClients={selectedClients}
        setSelectedClients={setSelectedClients}
        handleChange={handleChange}
        handleRemoveComponent={handleRemoveComponent}
        handleAddComponent={handleAddComponent}
        handleSubmit={handleSubmit}
        deleteModalOpen={deleteModalOpen}
        openDeleteModal={openDeleteModal}
        closeDeleteModal={closeDeleteModal}
        handleDelete={handleDelete}
        showDeleteButton={true}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant={"filled"}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </MainLayout>
  );
};

export default EditProductionPark;

import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Checkbox, Chip } from "@mui/material";
import TextField from "../../../../components/TextField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getMeteringPoints } from "../../../../api/resources/meteringPoints";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface MeteringPointsSelectionProps {
  selectedMeteringPointsOptions: any[];
  setSelectedMeteringPointsOptions: React.Dispatch<React.SetStateAction<any[]>>;
  formValues: any;
  setFormValues: React.Dispatch<React.SetStateAction<any>>;
}

const MeteringPointsSelection: React.FC<MeteringPointsSelectionProps> = ({
  selectedMeteringPointsOptions,
  setSelectedMeteringPointsOptions,
  formValues,
  setFormValues,
}) => {
  const [meteringPointsOptions, setMeteringPointsOptions] = useState([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    const delayInputTimeout = setTimeout(() => {
      if (searchString) {
        handleSearchMeteringPoint();
      }
    }, 300);
    return () => clearTimeout(delayInputTimeout);
  }, [searchString]);

  const handleSearchMeteringPoint = () => {
    getMeteringPoints(0, 15, null, searchString).then((result) => {
      const meteringPointsOptions = result?.data.content.map((c) => ({
        name: c.eic,
        id: c.id,
      }));
      setMeteringPointsOptions(meteringPointsOptions);
    });
  };

  const handleSelectMeteringPointsOption = (option: any) => {
    let selectedMeteringPointsOptionsData = [...selectedMeteringPointsOptions];
    if (
      selectedMeteringPointsOptionsData.find(
        (sc) => typeof sc === "object" && sc.value === option.value,
      )
    ) {
      selectedMeteringPointsOptionsData =
        selectedMeteringPointsOptionsData.filter(
          (sc) => typeof sc === "object" && sc.value !== option.value,
        );
    } else {
      selectedMeteringPointsOptionsData = [
        ...selectedMeteringPointsOptionsData,
        option,
      ];
    }
    setSelectedMeteringPointsOptions(selectedMeteringPointsOptionsData);
    const newFormValues = { ...formValues };
    newFormValues.selectedMeteringPointsOptions =
      selectedMeteringPointsOptionsData.map((smpd) =>
        typeof smpd === "object" ? smpd.value : smpd,
      );
    setFormValues(newFormValues);
  };

  const handleRemoveSelectedMeteringPoint = (option: any) => {
    let selectedMeteringPointsData = [...selectedMeteringPointsOptions];
    selectedMeteringPointsData = selectedMeteringPointsData.filter(
      (sc) => typeof sc === "object" && sc.value !== option.value,
    );
    setSelectedMeteringPointsOptions(selectedMeteringPointsData);
    const newFormValues = { ...formValues };
    newFormValues.selectedMeteringPointsOptions =
      selectedMeteringPointsData.map((smpd) =>
        typeof smpd === "object" ? smpd.value : smpd,
      );
    setFormValues(newFormValues);
  };

  return (
    <Box mr="0.5rem">
      <Autocomplete
        multiple
        id="selectedMeteringPointsOptions"
        onChange={(event, value) => {
          setSelectedMeteringPointsOptions(value);
        }}
        value={selectedMeteringPointsOptions}
        limitTags={2}
        disableCloseOnSelect
        renderTags={(value: { title: any; value: any }[], getTagProps) =>
          value.map((option, index) => {
            const { key } = getTagProps({ index });
            return (
              <Chip
                label={option.title}
                key={key}
                onDelete={() => handleRemoveSelectedMeteringPoint(option)}
                size="small"
                sx={{ mr: "4px", mb: "4px" }}
              />
            );
          })
        }
        options={
          meteringPointsOptions
            ? meteringPointsOptions.map((meteringPoint) => {
                return {
                  title: meteringPoint.name,
                  value: meteringPoint.id,
                };
              })
            : []
        }
        getOptionLabel={(option) => option.title}
        defaultValue={[]}
        size="small"
        renderInput={(params) => (
          <TextField
            id="client"
            params={params}
            minWidth={420}
            maxWidth={420}
            label="Metering points"
            placeholder="Search by metering point EIC"
            onChange={(e: any) => setSearchString(e.target.value)}
          />
        )}
        renderOption={(props, option) => {
          const isSelected = !!selectedMeteringPointsOptions.find(
            (sc) => typeof sc === "object" && sc.value === option.value,
          );

          return (
            <li
              {...props}
              style={{ padding: "0rem" }}
              onClick={() => handleSelectMeteringPointsOption(option)}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4 }}
                checked={isSelected}
              />
              {option.title}
            </li>
          );
        }}
      />
    </Box>
  );
};

export default MeteringPointsSelection;

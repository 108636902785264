import React, { useState } from "react";
import MainLayout from "../../../../../layouts/Main";
import { Alert, Box, IconButton, Snackbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProtectedPaths } from "routes";
import { createProductionPark } from "../../../../../api/resources/productionPark";
import { ProductionPark } from "../../../../../api/models/ProductionPark";
import { ProductionParkType } from "../../../../../api/models/enums/ProductionParkType";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProductionParkForm from "../Components/ProductionParkForm";
import { useProductionParkForm } from "../Util/util";

export interface ApiError {
  message: string;
  status: number;
  error: string;
  timestamp: string;
  path: string;
}

const AddProductionPark: React.FC = () => {
  const initialFormData: ProductionPark = {
    name: "",
    type: ProductionParkType.SOLAR,
    code: "",
    location: "",
    priceArea: "",
    clientId: 0,
    productionParkComponents: [],
    meteringPoints: [],
  };

  const {
    formData,
    setFormData,
    selectedCountry,
    selectedClients,
    setSelectedClients,
    handleChange,
    handleRemoveComponent,
    handleAddComponent,
    handleCountrySelect,
  } = useProductionParkForm(initialFormData);

  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success",
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const clientId = selectedClients[0]?.value || 0;
    const meteringPointIds = formData.meteringPoints
      .filter((point) => point !== null)
      .map((point) => point.id);

    const postData = {
      ...formData,
      clientId,
      meteringPoints: meteringPointIds,
    };

    try {
      await createProductionPark(postData);
      setSnackbarMessage("Production park submitted successfully");
      setSnackbarSeverity("success");
    } catch (error) {
      const apiError = error as ApiError;
      setSnackbarMessage(`${apiError.message}`);
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <MainLayout
      tabs={[
        { label: "Clients", path: "Clients" },
        { label: "Metering points", path: "MeteringPoints" },
        { label: "Contracts", path: "ClientContracts" },
        { label: "Production parks", path: "ProductionParks" },
      ]}
    >
      <Box sx={{ display: "flex", alignItems: "center", paddingBottom: 3 }}>
        <IconButton
          sx={{ mr: "1rem" }}
          onClick={() => navigate(`/${ProtectedPaths.ProductionParks}`)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component="h5">
          Add production park
        </Typography>
      </Box>
      <ProductionParkForm
        formData={formData}
        setFormData={setFormData}
        selectedCountry={selectedCountry}
        handleCountrySelect={handleCountrySelect}
        selectedClients={selectedClients}
        setSelectedClients={setSelectedClients}
        handleChange={handleChange}
        handleRemoveComponent={handleRemoveComponent}
        handleAddComponent={handleAddComponent}
        handleSubmit={handleSubmit}
        showDeleteButton={false}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          variant={"filled"}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </MainLayout>
  );
};

export default AddProductionPark;

import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { ForecaSymbols } from "helpers/foreca";

const ForecaTable = () => {
  const rows = ForecaSymbols.map((symbol) => {
    return {
      code: symbol.code,
      label: symbol.label,
      value: 0,
    };
  });

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Foreca coefficents</Typography>
        <Button variant="contained">Save</Button>
      </Box>
      <TableContainer component={Paper} sx={{ maxWidth: 600, mt: 4 }}>
        <Table sx={{ maxWidth: 600 }} size="small">
          <TableHead sx={{ bgcolor: "#eeeeee" }}>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Symbol</TableCell>
              <TableCell>Coefficent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.label}>
                <TableCell component="th" scope="row">
                  {row.code}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.label}
                </TableCell>
                <TableCell>
                  <TextField
                    id="coefficent"
                    type="number"
                    variant="standard"
                    size="small"
                    sx={{ maxWidth: "140px" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ForecaTable;
